<template>
    <b-container>
        <validation-observer ref="saveCampaignForm" #default="{ invalid }">
            <b-form id="saveCampaignForm" @submit.prevent="saveCampaign(invalid)">
                <b-card class="d-flex">
                    <b-row class="border rounded mx-1 mb-2 p-2 justify-content-center">
                        <b-col cols="1">
                            <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="flat-warning"
                                class="btn-icon m-0" to="/campaigns" v-b-tooltip.hover title="Go Back">
                                <feather-icon icon="ArrowLeftCircleIcon" size="20" />
                            </b-button>
                        </b-col>
                        <b-col cols="11" class="d-flex align-items-center">
                            <h1 class="mb-0">Website Campaign</h1>
                        </b-col>
                    </b-row>

                    <b-row class="border rounded mb-2 p-2 justify-content-center mx-1">
                        <b-col cols="12" class="mr-1">
                            <b-container>
                                <b-row>
                                    <b-col cols="4" class="mb-2">
                                        <h5 class="mb-0">Campaign Details :</h5>
                                        <small class="text-muted">
                                            Enter Your Campaign Details.
                                        </small>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-group label="Campaign name: *" label-for="h-campaign-name">
                                            <validation-provider #default="{ errors }" name="Campaign name"
                                                rules="required|regex:^[a-zA-Z].*$">
                                                <!-- rules="required|regex:/^[a-z|A-Z].*$/gi/" -->
                                                <b-form-input id="h-campaign-name" v-model="title"
                                                    placeholder="Campaign name"
                                                    :state="errors.length > 0 ? false : null" />
                                                <!-- @change="titleIntered" -->
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="6" offset="4">
                                        <b-form-group label="Domains: *" label-for="did">
                                            <validation-provider #default="{ errors }" name="Domains" rules="required">
                                                <v-select id="did" v-model="did"
                                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple
                                                    :state="errors.length > 0 ? false : null" label="title"
                                                    :options="domains" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </b-col>
                    </b-row>
                    <b-row class="border rounded mb-2 justify-content-center mx-1">
                        <b-col cols="12" class="ml-2 p-2">
                            <b-container>
                                <b-row>
                                    <b-col cols="4" class="mb-2">
                                        <h5 class="mb-0">Campaign Type :</h5>
                                        <small class="text-muted">
                                            Choose The Campaign Type.
                                        </small>
                                    </b-col>
                                    <b-col cols="2" v-for="item in templateModels" :key="item.id">
                                        <label class="pr-1">{{ item.title }} :</label>
                                        <b-img style="cursor: pointer"
                                            :class="{ templateModelIsSelected: item.selectedModal }" thumbnail fluid
                                            :src="path + item.image_preview" alt=""
                                            @click="campaignTypeClicked(item.id, item.title)" />
                                        <input type="hidden" v-model="campaignTypeSelected" />
                                    </b-col>
                                </b-row>
                            </b-container>
                        </b-col>
                    </b-row>
                    <b-row class="border rounded mx-1 mb-2 py-3 px-1 justify-content-center"
                        v-if="showAppAd && !showStoreAd">
                        <!-- <b-row v-if="!this.showAppAd == 1"> -->
                        <!-- <b-row v-if="this.showAppAd"> -->
                        <b-col cols="6" class="mr-2 border p-2 rounded">
                            <!-- <div v-if="this.campaignTypeOnTheSelection == 'app'"></div> -->
                            <b-container>
                                <b-row>
                                    <b-col cols="12" class="mb-2">
                                        <h5 class="mb-0">Campaign for an Application Ad :</h5>
                                        <small class="text-muted">
                                            Enter Your Campaign Template Details.
                                        </small>
                                    </b-col>

                                    <imgmng class="offset-md-7" />
                                    <!-- @onInsertImage="insertInsideTemplate" -->
                                    <b-col cols="12">
                                        <b-form-group label="Title: *" label-for="h-ad-title">
                                            <validation-provider #default="{ errors }" name="Title"
                                                rules="required|regex:^[a-zA-Z\u0600-\u06FF]+.*$">
                                                <b-form-input id="h-campaign-title" v-model="campaignAppAd.title"
                                                    placeholder="Campaign title"
                                                    :state="errors.length > 0 ? false : null" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-form-group label="Main Image: *" label-for="h-main-image">
                                            <validation-provider #default="{ errors }" name="Main Image link"
                                                rules="required|url">
                                                <b-form-input id="h-main-image" type="url"
                                                    v-model="campaignAppAd.headerImage"
                                                    placeholder="Enter Main Image link"
                                                    :state="errors.length > 0 ? false : null" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-form-group label="Icon Image: *" label-for="h-icon-image">
                                            <validation-provider #default="{ errors }" name="Icon Image link"
                                                rules="required|url">
                                                <b-form-input id="h-icon-image" type="url"
                                                    v-model="campaignAppAd.iconImage"
                                                    placeholder="Enter Icon Image link"
                                                    :state="errors.length > 0 ? false : null" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-form-group label="Short Description: *" label-for="h-short-description">
                                            <validation-provider #default="{ errors }" name="Short Description">
                                                <!-- rules="required|regex:^[a-zA-Z\u0600-\u06FF]+.*$" -->
                                                <b-form-textarea id="textarea-shortDescription"
                                                    placeholder="Enter the short description..." rows="8"
                                                    v-model="campaignAppAd.shortDescription"
                                                    :state="errors.length > 0 ? false : null" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </b-col>
                        <b-col cols="5">
                            <b-container class="border rounded textStart" style="height: 590px">
                                <b-row style="direction: rtl !important" class="text-wrap text-break">
                                    <b-col cols="12" class="bg-dark p-0 overflow-hidden">
                                        <div id="preview-headerImage" class="text-center img" style="height: 10rem">
                                            <!-- class="img-fluid img-responsive" -->
                                            <img v-if="campaignAppAd.headerImage" style="width: 100%; height: 100%"
                                                :src="campaignAppAd.headerImage" alt="" />
                                            <feather-icon v-if="!campaignAppAd.headerImage" icon="ImageIcon"
                                                class="align-middle h-100" size="50" />
                                        </div>
                                    </b-col>
                                    <b-col cols="3" class="mt-2">
                                        <div id="preview-iconImage" class="text-center bg-dark rounded-circle"
                                            style="height: 64px">
                                            <img v-if="campaignAppAd.iconImage" class="rounded-circle"
                                                style="width: 100%; height: 100%" :src="campaignAppAd.iconImage"
                                                alt="" />
                                            <feather-icon v-if="!campaignAppAd.iconImage" icon="ImageIcon"
                                                class="align-middle h-100" size="30" />
                                        </div>
                                    </b-col>
                                    <b-col cols="9" class="mt-2">
                                        <h5 v-if="!campaignAppAd.title" id="preview-title"
                                            class="card-title font-weight-bold">
                                            لوريم إيبسوم
                                        </h5>
                                        <h5 v-if="campaignAppAd.title" id="preview-title"
                                            class="card-title font-weight-bold">
                                            {{ campaignAppAd.title }}
                                        </h5>
                                    </b-col>

                                    <b-col cols="12" class="mt-1" style="direction: rtl">
                                        <p class="lh-lg" id="preview-shortDescription"
                                            v-if="!campaignAppAd.shortDescription">
                                            لوريم إيبسوم(Lorem Ipsum) هو ببساطة نص شكلي (بمعنى أن
                                            الغاية هي الشكل وليس المحتوى) ويُستخدم في صناعات المطابع
                                            ودور النشر. كان لوريم إيبسوم ولايزال المعيار للنص الشكلي
                                            منذ القرن الخامس عشر عندما قامت مطبعة مجهولة برص مجموعة من
                                            الأحرف بشكل عشوائي أخذتها من نص، لتكوّن كتيّب بمثابة دليل
                                            أو مرجع شكلي لهذه الأحرف. خمسة قرون من الزمن لم تقضي على
                                            هذا النص، بل انه حتى صار مستخدماً وبشكله الأصلي في الطباعة
                                            والتنضيد الإلكتروني. انتشر بشكل كبير في ستينيّات هذا القرن
                                            مع إصدار رقائق "ليتراسيت" (Letraset) البلاستيكية تحوي
                                            مقاطع من هذا النص، وعاد لينتشر مرة أخرى مؤخراَ مع ظهور
                                            برامج النشر الإلكتروني مثل "ألدوس بايج مايكر" (Aldus
                                            PageMaker) والتي حوت أيضاً على نسخ من نص لوريم إيبسوم.
                                        </p>
                                        <p class="lh-lg" id="preview-shortDescription"
                                            v-if="campaignAppAd.shortDescription">
                                            {{ campaignAppAd.shortDescription }}
                                        </p>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </b-col>
                    </b-row>
                    <br>
                    <b-row class="border rounded mx-1 mb-2 py-3 px-1 justify-content-center"
                        v-if="showAppAd && showStoreAd">
                        <b-col cols="12">
                            <b-form-group label="Product Content :" label-for="did">
                                <div>
                                    <vue-editor v-model="templateContentUpHtml" />
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="border rounded mb-2 mx-1 p-2 justify-content-center">
                        <b-col cols="12">
                            <b-container>
                                <b-row>
                                    <b-col cols="4" class="mb-2">
                                        <h5 class="mb-0">Campaign Settings :</h5>
                                        <small class="text-muted">
                                            Enter The Campaign Setting Details.
                                        </small>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-group label="Button Text: *" label-for="btn-text">
                                            <validation-provider #default="{ errors }" name="Button Text"
                                                rules="required|regex:^[a-zA-Z\u0600-\u06FF]+.*$">
                                                <b-form-input v-model="button_text"
                                                    :state="errors.length > 0 ? false : null" id="btn-text"
                                                    placeholder="Button Text" />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="6" offset="4">
                                        <b-form-group :label="showStoreAd ? 'Form submitted to:' : 'Button Link: *'"
                                            label-for="btnLink">
                                            <validation-provider #default="{ errors }" name="Button Link"
                                                rules="required">
                                                <b-form-input id="btnLink" v-model="button_link"
                                                    :state="errors.length > 0 ? false : null" type="url"
                                                    placeholder="https://www.example.com" trim />
                                                <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="6" offset="4" v-if="showStoreAd && showAppAd">
                                        <div class="d-flex align-items-center">
                                            <div class="mr-3">
                                                <label for="productSelect">Select a Product:</label>
                                                <b-form-select id="productSelect" v-model="selectedProduct"
                                                    :options="formattedProducts" value-field="id"
                                                    text-field="name"></b-form-select>
                                                <p>Selected Product ID: {{ selectedProduct }}</p>
                                            </div>
                                            <div v-if="selectedProductImage">
                                                <img :src="selectedProductImage" alt="Selected Product Image"
                                                    class="custom-img" style="width: 99px; height: 99px;" />
                                            </div>
                                            <br>
                                        </div>
                                        <br>
                                    </b-col>
                                </b-row>
                                <b-row v-if="showStoreAd && showAppAd">
                                    <b-col>
                                        <b-form-group label="Form Elements :" v-slot="{ ariaDescribedby }">
                                            <b-form-checkbox-group v-model="formItems" :options="formItemsOptions"
                                                :aria-describedby="ariaDescribedby" name="form_elements" stacked
                                                switches>
                                            </b-form-checkbox-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <div v-for="item in sortedFormItems" :key="item">
                                            <b-form-group>
                                                <b-form-input :placeholder="capitalize(item) + '...'"
                                                    style="height: 35px;" :disabled="true"></b-form-input>
                                            </b-form-group>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </b-col>
                    </b-row>
                    <div class="border rounded p-2 mx-1 mb-0">
                        <div class="d-flex align-items-center justify-content-center" offset="4"
                            style="padding-left: 1.4rem !important">
                            <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="outline-success" class="mr-1"
                                type="submit">
                                Submit
                            </b-button>
                            <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="outline-secondary"
                                @click="comfirmCancelCreation">
                                Cancel
                            </b-button>
                        </div>
                    </div>
                </b-card>
            </b-form>
        </validation-observer>
        <b-modal id="modal-cancel-creation" ok-variant="warning" ok-title="Confirm" modal-class="modal-warning" centered
            title="Cancel Creating!" @ok="cancelCreation">
            <b-card-text>
                Are you sure you want to cancel campaign creation ?
            </b-card-text>
        </b-modal>
    </b-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";
import vSelect from "vue-select";
import { VueEditor } from "vue2-editor";

import {
    BRow,
    BCol,
    BModal,
    BCard,
    BFormSelect,
    BTable,
    BInputGroup,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BFormFile,
    BImg,
    BForm,
    BInputGroupAppend,
    BButtonGroup,
    BCardText,
    VBTooltip,
    BPagination,
    BButton,
    VBHover,
    BContainer,
    BFormInvalidFeedback,
    BAspect,
    BFormCheckboxGroup,
} from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import axios from "@axios";
import wp_api from "@/libs/apis/wp_api";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import imagesManagement from "@/views/ads-manager/media-management/ImagesManagement.vue";
import addOns from "@/views/ads-manager/media-management/AddOns.vue";
import { ref } from "@vue/composition-api";
import {
    composeHeaderImage,
    composeShortDescription,
    composeIconImageAndTitle,
    composeContainer,
} from "@/helper/helpers-funcs.js";

export default {
    components: {

        VueEditor,
        ValidationProvider,
        ValidationObserver,
        BRow,
        BCol,
        BFormTextarea,
        BCard,
        BContainer,
        BFormInput,
        BFormGroup,
        BFormFile,
        BImg,
        BFormSelect,
        BCardText,
        BInputGroupAppend,
        BForm,
        BTable,
        BInputGroup,
        BModal,
        addons: addOns,
        imgmng: imagesManagement,
        BButtonGroup,
        VBTooltip,
        BPagination,
        BButton,
        vSelect,
        BAspect,
        BFormInvalidFeedback,
        BFormCheckboxGroup,
    },
    directives: {
        "b-tooltip": VBTooltip,
        "b-hover": VBHover,
        Ripple,
    },
    data() {
        return {
            products: [],
            selectedProduct: null,
            path: this.$ImagesURL + "template/",
            perPage: 10,
            pageOptions: [10, 25, 100],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            infoModal: {
                id: "info-modal",
                title: "",
                content: "",
            },
            fields: [
                {
                    key: "id",
                    label: "Id",
                },
                {
                    key: "url",
                    label: "Image URL",
                },
                {
                    key: "action",
                    label: "ACTION",
                },
            ],
            hello: "hey its me",
            imagesItems: [],
            //###########################
            aspect: "16:9",
            button_text: null,
            button_link: null,
            title: null,
            // isSubDisabled: true,
            url: null,
            image: null,
            imageURL: null,
            templateModelIsHovered: [],
            did: null,
            templateContent: null,
            templateContentUpHtml: null,
            campaignTypeSelected: null,
            campaignTypeOnTheSelection: null,
            templateModels: [],
            domains: [],
            data: {},
            campaignAppAd: {
                title: null,
                shortDescription: null,
                headerImage: null,
                iconImage: null,
            },
            showAppAd: false,
            showStoreAd: false,
            //########## validation vars ###########
            titleState: true,
            // validation rules
            required,
            regex,
            url,
            region: null,
            countries: [],
            allCountries: [],
            formItems: [],
            formItemsOptions: [
                { text: "Full Name", value: "full_name" },
                { text: "Phone", value: "phone" },
                { text: "Address", value: "address" },
                { text: "City", value: "city" },
            ],
        };
    },
    computed: {
        formattedProducts() {
            return this.products.map(product => ({
                id: product.id,
                name: this.decodeHtmlEntities(product.name),
            }));
        },
        selectedProductImage() {
            const product = this.products.find(p => p.id === this.selectedProduct);
            return product ? product.images[0] : null;
        },
        sortedFormItems() {
            const order = this.formItemsOptions.map((option) => option.value);
            return this.formItems.slice().sort((a, b) => order.indexOf(a) - order.indexOf(b));
        },
    },
    created() {
        this.showAllDomains();
    },
    watch: {
        showStoreAd(newVal) {
            if (newVal) {
                this.button_link = "https://api.chritoo.ma/api/add-wp-order";
            }
        },
    },
    mounted() {
        this.showAllTemplateModels();
        this.showAppAd = false;
        this.showStoreAd = false;
        this.getCountries();
        this.getWpproducts();
    },

    methods: {
        decodeHtmlEntities(encodedStr) {
            const txt = document.createElement('textarea');
            txt.innerHTML = encodedStr;
            let decoded = txt.value;
            if (decoded.startsWith("<p>") && decoded.endsWith("</p>")) {
                decoded = decoded.slice(3, -4);
            }
            return decoded;
        },
        async getWpproducts() {
            try {
                const response = await wp_api.get('/get-wp-products');
                console.log('API Response:', response.data);
                if (response.data && response.data.status === 'success') {
                    this.products = response.data.data.products;
                } else {
                    console.error('Failed to fetch products:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching products:', error.response ? error.response.data : error);
            }
        },
        capitalize(str) {
            return str == "full_name" ? "Full Name" : str.charAt(0).toUpperCase() + str.slice(1);
        },
        getCountries() {
            axios.get("countries").then(({ data }) => {
                this.allCountries = [];
                data.countries.forEach(({ id, name }) => {
                    this.allCountries.push(name);
                });
            });
        },
        comfirmCancelCreation() {
            this.$root.$emit("bv::show::modal", "modal-cancel-creation");
        },
        cancelCreation() {
            this.$router.push({ name: "campaigns" });
        },
        templateComposer() {
            if (
                (this.campaignAppAd.title &&
                    this.campaignAppAd.shortDescription &&
                    this.campaignAppAd.headerImage &&
                    this.campaignAppAd.iconImage) != null
            ) {
                const headerImage = composeHeaderImage(
                    this.campaignAppAd.headerImage,
                    this.campaignAppAd.title
                );
                const iconImageAndTitle = composeIconImageAndTitle(
                    this.campaignAppAd.iconImage,
                    this.campaignAppAd.title
                );
                const shortDescription = composeShortDescription(
                    this.campaignAppAd.shortDescription
                );
                const container = composeContainer(
                    headerImage,
                    iconImageAndTitle,
                    shortDescription
                );
                return container;
            }
        },
        formSubmitted() {
            this.showToast(
                "warning",
                "AlertTriangleIcon",
                "Form Submitted",
                "Form Submitted!"
            );
        },
        previewTemplateOfCampaign() {
            localStorage.setItem(
                "templateContent",
                JSON.stringify(this.templateContent)
            );
            let routeData = router.resolve({
                name: "editor-preview-content",
            });
            window.open(routeData.href, "_blank");
        },
        templateModelHovered(hovered) {
            this.templateModelIsHovered = hovered;
        },
        showToast(variant, icon, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    text,
                    variant,
                },
            });
        },
        async showAllDomains() {
            this.$store
                .dispatch("appsManager/getAllNotReferredDomains")
                .then(({ data }) => {
                    data[0].forEach((element) => {
                        this.domains.push({
                            title: element.domain_name,
                            value: element.id,
                        });
                    });
                })
                .catch(console.error);
        },
        campaignTypeClicked(id, type) {
            this.templateModels.forEach((element) => {
                element.selectedModal = false;
                if (element.id == id) {
                    element.selectedModal = true;
                }
                this.campaignTypeOnTheSelection = type;
                if (type == "app" || type == "App") {
                    this.showAppAd = true;
                    this.showStoreAd = false;
                } else if (type == "store" || type == "Store") {
                    this.showAppAd = true;
                    this.showStoreAd = true;
                } else {
                    this.showAppAd = false;
                    this.showStoreAd = false;
                }
                this.campaignTypeSelected = id;
            });
        },
        async showAllTemplateModels() {
            this.$store
                .dispatch("appsManager/getAllTemplateModels")
                .then(({ data }) => {
                    data["template-models"].forEach((element) => {
                        element.selectedModal = false;
                    });
                    this.templateModels = data["template-models"];
                })
                .catch(console.error);
        },
        async saveCampaign(i) {
            if (!i) {
                let values = [];
                if (this.did == null) {
                    this.showToast(
                        "warning",
                        "AlertTriangleIcon",
                        "Warning",
                        "Make sure you relate the campaign to a domain!"
                    );
                    return;
                }
                this.did.forEach((element) => {
                    values.push(element.value);
                });
                if (values.length > 0) {
                    let btnID;
                    await axios.post("/store-button", {
                        campaign_type: this.showStoreAd ? 'store' : 'app',
                        text: this.button_text,
                        button_link: this.button_link,
                        form_elements: this.sortedFormItems,
                        product_id: this.selectedProduct,
                    }).then(({ data }) => {
                        if (data.status == "success") {
                            btnID = data.button_id;
                        } else if (data.status == "failed") {
                            this.showToast(
                                "danger",
                                "AlertTriangleIcon",
                                "Error",
                                data.message
                            );
                            return;
                        }
                    });
                    let allCampaignColumns = {
                        title: this.title,
                        did: values,
                        button_id: btnID,
                        campaign_type: this.campaignTypeOnTheSelection,
                        countries: this.countries,

                    };
                    if (this.campaignTypeOnTheSelection == "app") {
                        allCampaignColumns.title_beside_icon = this.campaignAppAd.title;
                        allCampaignColumns.header_image = this.campaignAppAd.headerImage;
                        allCampaignColumns.icon_image = this.campaignAppAd.iconImage;
                        allCampaignColumns.short_description = this.campaignAppAd.shortDescription;
                        allCampaignColumns.template = this.templateComposer();

                    } else if (this.campaignTypeOnTheSelection == "store") {
                        let sanitizedTemplate = this.decodeHtmlEntities(this.templateContentUpHtml);
                        if (sanitizedTemplate.startsWith("<p>") && sanitizedTemplate.endsWith("</p>")) {
                            sanitizedTemplate = sanitizedTemplate.slice(3, -4);
                        }
                        allCampaignColumns.template = sanitizedTemplate;
                        allCampaignColumns.product_id = this.selectedProduct;

                    }

                    await axios
                        .post("/store-campaign", allCampaignColumns)
                        .then(({ data }) => {
                            if (data.status == "success") {
                                this.showToast("success", "SaveIcon", "Done", data.message);
                                this.did = null;
                                router.push({ name: "campaigns" });
                            } else if (data.status == "failed") {
                                this.showToast(
                                    "danger",
                                    "AlertTriangleIcon",
                                    "Error",
                                    data.message
                                );
                            }
                        })
                        .catch((error) => {
                            this.showToast(
                                "danger",
                                "AlertTriangleIcon",
                                "Error",
                                "Something went wrong!"
                            );
                        });
                } else {
                    this.showToast(
                        "danger",
                        "AlertTriangleIcon",
                        "Error",
                        "All Fields are required!"
                    );
                }
            }
        },



        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`;
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.custom-img {
    border: 2px solid #ddd;
    /* Customize border color and thickness */
    border-radius: 15px;
    /* Customize roundness */
}

.hellome:hover {
    border: 3.5px solid #f6ff00;
}

.border-info {
    border-width: 3px !important;
}

.templateModelIsHovered:hover {
    border: 3.5px solid #1094f3;
    padding: 0;
    cursor: pointer;
}

.templateModelIsSelected {
    border: 3.5px solid #1094f3;
    padding: 0;
}

.submitDisabled {
    cursor: no-drop;
}

.submitEnabled {
    cursor: pointer;
}

.textStart {
    text-align: start;
}

.font-weight-bold {
    font-weight: bold !important;
}
</style>