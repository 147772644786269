import Vue from "vue";

// axios
import axios from "axios";

// Global variables
let theBaseURL = "https://api.chritoo.ma/api/";
// let theBaseURL = "http://localhost:3000/api/";

const axiosIns = axios.create({
    baseURL: theBaseURL,
    headers: {
        "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
    },
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
