<template>
  <b-card>
    <validation-observer ref="editDomainForm" #default="{ invalid }">
      <b-form id="editDomainForm" @submit.prevent="updateDomain(domainId)">
        <b-container>
          <b-row class="justify-content-md-center">
            <b-col cols="12" md="2">
              <b-button
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="outline-warning"
                to="/domains"
                v-b-tooltip.hover
                title="Go Back"
              >
                <feather-icon icon="ArrowLeftCircleIcon" size="20" />
              </b-button>
            </b-col>
            <b-col cols="12" md="10">
              <h1>Edit the Domain</h1>
            </b-col>
          </b-row>
          <hr />
          <b-row class="justify-content-md-center">
            <b-col md="6" cols="12">
              <b-form-group label="Account Name: *" label-for="d-name">
                <validation-provider
                  #default="{ errors }"
                  name="Account Name"
                  rules="required|regex:^[a-zA-Z].*$"
                >
                  <b-form-input
                    id="d-name"
                    v-model="domain_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Domain Name"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="justify-content-md-center">
            <b-col md="6" cols="12">
              <!-- Event URL -->
              <validation-provider name="Event URL" rules="url">
                <b-form-group label="Account Link: *" label-for="event-url">
                  <validation-provider
                    #default="{ errors }"
                    name="Account Link"
                    rules="required|url"
                  >
                    <b-form-input
                      id="event-url"
                      v-model="domain_link"
                      type="url"
                      placeholder="https://www.example.com"
                      :state="errors.length > 0 ? false : null"
                      trim
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <imgmng @onInsertImage="insertInsideTemplate" />
          <b-row class="justify-content-md-center">
            <b-col cols="12">
               <b-form-group label="Product Content :" label-for="did">
                <div>
                <vue-editor v-model="templateContentUpHtml" />
                 </div>
                </b-form-group>
            </b-col>
          </b-row>
          <b-row class="justify-content-md-center">
            <b-col>
              <!-- <b-col offset-md="4"> -->
              <b-button
                type="submit"
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="outline-warning"
                class="mr-1"
                :disabled="invalid"
              >
                Update
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                @click="previewTemplateOfCampaign"
              >
                Preview
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";
import { VueEditor } from "vue2-editor";

import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BFormFile,
  BForm,
  BImg,
  VBTooltip,
  BButton,
  BContainer,
} from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import axios from "@axios";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import Editor from "@tinymce/tinymce-vue";
import imagesManagement from "@/views/ads-manager/media-management/ImagesManagement.vue";

export default {
  components: {
    VueEditor,
    BRow,
    BCol,
    BCard,
    BContainer,
    BFormInput,
    BFormGroup,
    BFormFile,
    BForm,
    BImg,
    imgmng: imagesManagement,
    VBTooltip,
    BButton,
    editor: Editor,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      domain_name: null,
      domain_link: null,
      image: null,
      domain_logo: null,
      templateContentUpHtml: null,
      data: {},
      required,
      regex,
      url,
      path: this.$ImagesURL,
    };
  },
  computed: {
    domainId() {
      return this.$store.state.appsManager.domainID;
    },
    allDomains() {
      return this.$store.state.appsManager.domains;
    },
  },
  created() {
    this.appendDomain();
  },
  methods: {
    decodeHtmlEntities(encodedStr) {
          const txt = document.createElement('textarea');
          txt.innerHTML = encodedStr;
          let decoded = txt.value;
          if (decoded.startsWith("<p>") && decoded.endsWith("</p>")) {
              decoded = decoded.slice(3, -4);
          }
          return decoded;
      },
    escapeHTML(input) {
            return input
                .replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/'/g, "&#39;");
        },
    formatTemplate(content) {
         
         const baseTemplate = `
     <!DOCTYPE html>
     <html>
     <head>
     </head>
     <body>${content}</body>
     </html>
         `;
         return baseTemplate
       },

    insertInsideTemplate(img) {
      const bodyTag = "</body>";
      let thereIsBody = this.templateContentUpHtml.search(bodyTag);
      if (thereIsBody > 0) {
        let imgAdded = this.templateContentUpHtml.replace("</body>", img + "</body>");
        this.templateContent = imgAdded;
        return;
      }
      this.templateContentUpHtml += img;
    },
    previewTemplateOfCampaign() {
      localStorage.setItem(
        "templateContent",
        JSON.stringify(this.templateContentUpHtml)
      );
      let routeData = router.resolve({
        name: "editor-preview-content",
      });
      window.open(routeData.href, "_blank");
    },
    appendDomain(id) {
      if (this.allDomains.length > 0) {
        let items = this.allDomains;
        if (this.domainId != undefined) {
          let _domain = items.find((o) => o.id == this.domainId);
          
          this.domain_name = _domain.domain_name;
          this.domain_link = _domain.domain_link;
          // this.templateContent = _domain.template

          let decodedTemplate = _domain.template
        .replace(/<!DOCTYPE html>/i, '') 
        .replace(/<html[^>]*>/i, '') 
        .replace(/<\/html>/i, '') 
        .replace(/<head[^>]*>.*<\/head>/is, '') 
        .replace(/<body[^>]*>/i, '')
        .replace(/<\/body>/i, ''); 
      const escapedTemplate = this.escapeHTML(decodedTemplate);
      this.templateContentUpHtml=escapedTemplate;
      
      console.log(escapedTemplate);

       
        }
      } else {
        this.$store
          .dispatch("appsManager/getAllDomains")
          .then(({ data }) => {
            if (this.domainId != undefined) {
              let _domain = data[0].find((o) => o.id == this.domainId);
              this.domain_name = _domain.domain_name;
              this.domain_link = _domain.domain_link;
            
              let decodedTemplate = _domain.template
              .replace(/<!DOCTYPE html>/i, '') 
              .replace(/<html[^>]*>/i, '') 
              .replace(/<\/html>/i, '') 
              .replace(/<head[^>]*>.*<\/head>/is, '') 
              .replace(/<body[^>]*>/i, '')
              .replace(/<\/body>/i, ''); 
            const escapedTemplate = this.escapeHTML(decodedTemplate);
            this.templateContentUpHtml=escapedTemplate;
                  }
          })
          .catch(console.error);
      }
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    ImageInputChanged(e) {
      // this.domain_logo = e.target.files[0];
    },
    async updateDomain(id) {
      if (!this.domain_link && !this.domain_name && !this.templateContentUpHtml) {
        this.showToast(
          "warning",
          "AlertOctagonIcon",
          "Alert",
          `You need to fill a field so as updating it.`
        );
        return;
      }
      // let inputsData = new FormData();
      // inputsData.append("id", id);
      // inputsData.append("domain_link", this.domain_link);
      // inputsData.append("domain_name", this.domain_name);
      // inputsData.append("domain_logo", this.domain_logo);

      const regExp = /[\/]$/;
      let results = regExp.test(this.domain_link);
      if (!results) this.domain_link += "/";
      
    //   const formattedTemplate = this.formatTemplate(
    //   this.decodeHtmlEntities(this.templateContentUpHtml)
    // );
    let sanitizedTemplate = this.decodeHtmlEntities(this.templateContentUpHtml);
        if (sanitizedTemplate.startsWith("<p>") && sanitizedTemplate.endsWith("</p>")) {
            sanitizedTemplate = sanitizedTemplate.slice(3, -4);
        }
        
     const formattedTemplate = this.formatTemplate(sanitizedTemplate);
      await axios .post("/update-domain", {
          id: this.domainId,
          domain_link: this.domain_link,
          domain_name: this.domain_name,
          template: formattedTemplate,
        })
        .then(({ data }) => {
          if (data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            router.push({ name: "domains" });
            return;
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Something went wrong!"
          );
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
